import {createTheme} from '@mui/material/styles';

const AppTheme = createTheme({
    palette: {
        secondary: {
            main: "#2E4053"
        }
    }
})




export default AppTheme;